import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { useEffect, useState } from "react";
import $ from "jquery";
import apiURL from "../../config/environment";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { isNumberPositiveInt } from "../../utils";
import CheckIcon from "@mui/icons-material/Check";

export const AddSegmentDialog = ({
  open,
  handleClose,
  existingRows,
  setExistingRows,
}) => {
  const [iterisRoute, setIterisRoute] = useState("");
  const [location, setLocation] = useState("");
  const [pmaDensity, setPmaDensity] = useState("");
  const [archived, setArchived] = useState(false);
  const [iterisRouteError, setIterisRouteError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const handleDialogClose = () => {
    setIterisRoute("");
    setLocation("");
    setPmaDensity("");
    setArchived(false);
    setSaveError(false);
    setIterisRouteError(false);
    handleClose();
  };

  useEffect(() => {
    isNumberPositiveInt(iterisRoute, "Route ID", setIterisRouteError, true);
  }, [iterisRoute]);

  if (!open) return null;

  const handleSave = () => {
    if (!isNumberPositiveInt(iterisRoute, "Route ID", setIterisRouteError)) {
      return;
    }

    if (existingRows.find((row) => row.id === iterisRoute)) {
      setIterisRouteError("Route ID already exists");
      return;
    }
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "POST",
      url: apiURL + "admin/site/iteris",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      contentType: "application/json",
      data: JSON.stringify({
        id: iterisRoute,
        name: location,
        mmaType: pmaDensity,
        archived: archived,
      }),
    })
      .done((data) => {
        setExistingRows([...existingRows, data]);
        handleDialogClose();
      })
      .fail((jqXHR, textStatus, errorThrows) => {
        console.error("Error adding Segment", jqXHR, textStatus, errorThrows);
        setSaveError(true);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleDialogClose();
      }}
    >
      <Box sx={{ width: "444px", padding: "16px 24px 24px 24px" }}>
        <DialogTitle sx={{ padding: 0 }}>New Segment</DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            {saveError && (
              <Alert severity={"error"}>
                Unable to save. Please try again later.
              </Alert>
            )}
            <TextField
              label="Iteris Route"
              type={"text"}
              error={iterisRouteError ? true : false}
              helperText={iterisRouteError}
              value={iterisRoute}
              onChange={(e) => setIterisRoute(e.target.value)}
              autoFocus
              fullWidth
            />
            <TextField
              label="Location"
              type={"text"}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              fullWidth
            />
            <FormControl fullWidth>
              <InputLabel id={"pma-density-label"}>PMA Density</InputLabel>
              <Select
                labelId={"pma-density-label"}
                label="PMA Density"
                onChange={(e) => setPmaDensity(e.target.value)}
                value={pmaDensity}
                fullWidth
              >
                <MenuItem value="Low Density">Low</MenuItem>
                <MenuItem value="Medium Density">Medium</MenuItem>
                <MenuItem value="High Density">High</MenuItem>
              </Select>
            </FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => setArchived(e.target.checked)} />
                }
                label="Hidden"
                aria-describedby="hiddenHelp"
                style={{ paddingLeft: "13px" }}
              />
              <FormHelperText id="hiddenHelp">
                Hidden segments are not included in metric calculations
              </FormHelperText>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 0, marginTop: "16px" }}>
          <Button
            onClick={() => {
              handleDialogClose();
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={
              !iterisRoute || !location || !pmaDensity || iterisRouteError
                ? true
                : false
            }
            variant={"contained"}
            sx={{
              backgroundColor: "rgba(25, 118, 210, 1)",
              gap: "8px",
            }}
          >
            <CheckIcon sx={{ width: "16px", height: "24px" }} />
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
