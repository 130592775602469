import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

//Selfmade
import { useNavigate, useLocation } from "react-router-dom";
import {
  ButtonBase,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import { ThemeProvider } from "@emotion/react";
import darkTheme from "../../config/secondaryTheme";
import { Logo } from "../icons/Logo";
import CameraIcon from "../icons/CameraIcon";
import TwoCarsIcon from "../icons/TwoCarsIconsForNarvar";
import SelectPeriod from "../input/selectPeriod";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import Divider from "@mui/material/Divider";
import { NavigationTabs } from "../admin/navigationTabs";

export default function Navbar({ isAuthenticated }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [anchorElMenu, setAnchorElMenu] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("userInfo");
    navigate("/login");
  };

  const handlePageChange = (event, newPage) => {
    navigate(newPage);
  };

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Grid
          container
          spacing={1}
          justifyContent={"space-between"}
          sx={{
            width: "100%",
          }}
        >
          <Grid display={"flex"} alignItems={"center"}>
            <Logo color="white" size="45" />
            <Typography
              variant="h6"
              component="h1"
              sx={{
                fontWeight: 600,
                pl: 2,
                display: { xs: "none", md: "block" },
              }}
            >
              {"Bellevue Transportation Department"}
            </Typography>
          </Grid>
          {isAuthenticated && location.pathname.includes("/administration") ? (
            <>
              <Grid display={"flex"} alignItems={"flex-end"}>
                <ThemeProvider theme={darkTheme}>
                  <NavigationTabs />
                </ThemeProvider>
              </Grid>
            </>
          ) : null}
          <Grid display={"flex"} alignItems={"center"} gap={"32px"}>
            {isAuthenticated &&
            location.pathname.includes("/administration") ? (
              <Grid
                display={"flex"}
                alignItems={"center"}
                size={2}
                sx={{ gap: "32px" }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Typography variant="h6" component="h2">
                    Administration
                  </Typography>
                </ThemeProvider>
              </Grid>
            ) : (
              <Grid
                container
                display={"flex"}
                justifyContent={"flex-end"}
                gap={"32px"}
              >
                <Grid size={3} display={"flex"} alignItems={"center"}>
                  <ThemeProvider theme={darkTheme}>
                    <ToggleButtonGroup
                      value={location.pathname}
                      exclusive
                      onChange={handlePageChange}
                    >
                      <ToggleButton
                        value={
                          location.pathname !== "/maintenance"
                            ? location.pathname
                            : "/"
                        }
                      >
                        <TwoCarsIcon
                          selected={location.pathname !== "/maintenance"}
                        />

                        <span
                          style={{
                            marginRight:
                              location.pathname !== "/maintenance"
                                ? "0px"
                                : "30px",
                          }}
                        >
                          Traffic Operations
                        </span>
                      </ToggleButton>
                      <ToggleButton value="/maintenance">
                        <CameraIcon
                          selected={location.pathname === "/maintenance"}
                        />
                        Device Maintenance
                        {/* </Typography> */}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ThemeProvider>
                </Grid>
                {isAuthenticated ? (
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"end"}
                    size={3}
                  >
                    <ThemeProvider theme={darkTheme}>
                      <SelectPeriod />
                    </ThemeProvider>
                  </Grid>
                ) : null}
              </Grid>
            )}
            <Grid display={"flex"} alignItems={"center"} size={2}>
              {isAuthenticated ? (
                <>
                  <ThemeProvider theme={darkTheme}>
                    <IconButton
                      size="large"
                      edge="end"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleOpenMenu}
                      sx={{
                        borderRadius: "100%",
                        height: "48px",
                        width: "48px",
                        backgroundColor: Boolean(anchorElMenu)
                          ? "rgba(255, 255, 255, 0.25)"
                          : "transparent",
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </ThemeProvider>
                  <Menu
                    open={Boolean(anchorElMenu)}
                    onClose={handleCloseMenu}
                    onClick={handleCloseMenu}
                    anchorEl={anchorElMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      key={"dashboard"}
                      onClick={() => navigate("/")}
                      sx={{
                        height: "36px",
                      }}
                      selected={
                        location.pathname === "/" ||
                        location.pathname === "/maintenance"
                      }
                    >
                      <IconButton>
                        <DashboardIcon />
                      </IconButton>
                      Dashboard
                    </MenuItem>
                    <MenuItem
                      key={"curbside"}
                      onClick={() => navigate("/curbside")}
                      sx={{
                        height: "36px",
                      }}
                      selected={location.pathname === "/curbside"}
                    >
                      <IconButton>
                        <AddRoadIcon />
                      </IconButton>
                      Curbside
                    </MenuItem>
                    <MenuItem
                      key={"administration"}
                      onClick={() => navigate("/administration/intersections")}
                      sx={{
                        height: "36px",
                      }}
                      selected={
                        location.pathname === "/administration/intersections"
                      }
                    >
                      <IconButton>
                        <SettingsIcon />
                      </IconButton>
                      Administration
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      key={"logout"}
                      onClick={handleLogout}
                      sx={{
                        height: "36px",
                      }}
                    >
                      <IconButton>
                        <LogoutIcon />
                      </IconButton>
                      {"Sign out"}
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <ButtonBase
                  color="inherit"
                  sx={{ fontWeight: 600 }}
                  onClick={() => navigate("/login")}
                >
                  {"Sign in"}
                </ButtonBase>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
