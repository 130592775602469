import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#006798",
      light: "#008ACC",
    },
    secondary: {
      main: "#888C8C",
    },
    success: {
      main: "#28A44D",
    },
    error: {
      main: "#C33149",
    },
    warning: {
      main: "#ED7D31",
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          variants: [
            {
              props: { variant: "Low Density" },
              style: {
                backgroundColor: "rgb(236, 253, 243)",
                color: "rgb(6, 118, 71)",
                border: "1px solid rgb(171, 239, 198)",
              },
            },
            {
              props: { variant: "Medium Density" },
              style: {
                backgroundColor: "rgb(255, 250, 235)",
                color: "rgb(181, 71, 8)",
                border: "1px solid rgb(254, 223, 137)",
              },
            },
            {
              props: { variant: "High Density" },
              style: {
                backgroundColor: "rgb(254, 243, 242)",
                color: "rgb(180, 35, 24)",
                border: "1px solid rgb(254, 205, 202)",
              },
            },
            {
              props: { variant: "City" }, // same as "Low Density"
              style: {
                backgroundColor: "rgb(236, 253, 243)",
                color: "rgb(6, 118, 71)",
                border: "1px solid rgb(171, 239, 198)",
              },
            },
            {
              props: { variant: "Regional" },
              style: {
                backgroundColor: "rgb(244, 243, 255)",
                color: "rgb(89, 37, 220)",
                border: "1px solid rgb(217, 214, 254)",
              },
            },
          ],
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
});

export default theme;
