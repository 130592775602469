import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import apiURL from "../../config/environment";
import $ from "jquery";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { formatDate, orderAdminRowsBy } from "../../utils";
import { AddSegmentDialog } from "./addSegmentDialog";
import { EditSegmentDialog } from "./editSegmentDialog";
import { useNavigate } from "react-router-dom";
import Copyright from "../copyright";
import { OrderIcon } from "../icons/OrderIcon";

export const SegmentsTab = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [rows, setRows] = useState([]);
  const [sortedRows, setSortedRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showArchived, setShowArchived] = useState(true);
  const [openAddSegmentDialog, setOpenAddSegmentDialog] = useState(false);
  const [openEditSegmentDialog, setOpenEditSegmentDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "GET",
      url: apiURL + "admin/sites/iteris",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .done((data) => {
        orderAdminRowsBy(data, orderBy, order, setRows);
      })
      .fail((res) => {
        if (res.status === 401) {
          localStorage.removeItem("userInfo");
          navigate("/login");
        }
      });
  }, []);

  useEffect(() => {
    let filtered = rows;

    // Filter rows based on searchText
    if (searchText && searchText.length > 0) {
      filtered = filtered.filter((row) => {
        return (
          row.id.toString().includes(searchText) ||
          row.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.mmaType.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }

    // Further filter rows based on showArchived
    if (!showArchived) {
      filtered = filtered.filter((row) => !row.archived);
    }

    // Sort the (possibly) filtered rows
    if (filtered.length !== rows.length) {
      orderAdminRowsBy(filtered, orderBy, order, setFilteredRows);
    } else {
      orderAdminRowsBy(rows, orderBy, order, setSortedRows);
    }
  }, [rows, searchText, showArchived, order, orderBy]);

  const handleSearch = (e) => {
    const search = e.target.value;
    setSearchText(search);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const headCells = [
    { id: "id", numeric: true, label: "Iteris Route" },
    { id: "archived", numeric: false, label: "" },
    { id: "name", numeric: false, label: "Location" },
    {
      id: "mmaType",
      numeric: false,
      label: "PMA Density",
    },
    {
      id: "modifiedDate",
      numeric: false,
      label: "Last Modified",
    },
    {
      id: "modifiedBy",
      numeric: false,
      label: "Modified By",
    },
  ];

  const rowsToDisplay =
    !showArchived || searchText.length > 0 ? filteredRows : sortedRows;

  return (
    <Box sx={{ padding: "36px 32px 16px 32px" }}>
      <Card sx={{ borderRadius: "8px" }}>
        <CardContent sx={{ padding: "16px 32px" }}>
          <Box
            sx={{
              margin: "16px 0",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box display={"flex"} alignItems={"center"} gap={"32px"}>
              <Typography variant="h5" component="h3">
                Segments
              </Typography>
              <OutlinedInput
                id="outlined-adornment-weight"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                endAdornment={
                  searchText.length > 0 && (
                    <InputAdornment position="end" onClick={handleClearSearch}>
                      <IconButton>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                placeholder={"Search"}
                sx={{
                  width: "300px",
                  height: "40px",
                }}
                onChange={handleSearch}
                value={searchText}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showArchived}
                    onChange={(e) => setShowArchived(e.target.checked)}
                  />
                }
                label="Show hidden"
              />
            </Box>

            <Box display={"flex"} alignItems={"center"}>
              <Button
                variant={"contained"}
                startIcon={<AddIcon />}
                sx={{
                  textTransform: "none",
                  backgroundColor: "rgba(19, 151, 203, 1)",
                  borderRadius: "6px",
                  fontSize: "15px",
                  fontWeight: 600,
                  letterSpacing: "0.15px",
                }}
                onClick={() => setOpenAddSegmentDialog(true)}
              >
                {"New Segment"}
              </Button>
              <AddSegmentDialog
                open={openAddSegmentDialog}
                handleClose={() => setOpenAddSegmentDialog(false)}
                handleSave={() => setOpenAddSegmentDialog(false)}
                existingRows={rows}
                setExistingRows={setRows}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant={"body2"} sx={{ color: "grey" }}>
              {rowsToDisplay.length} items
            </Typography>
          </Box>
          <Box>
            <TableContainer
              sx={{
                height: "calc(100vh - 294px)",
                minHeight: "500px",
                overflowX: "auto",
                marginTop: "8px",
              }}
            >
              <EditSegmentDialog
                handleClose={() => {
                  setOpenEditSegmentDialog(false);
                }}
                existingRows={rows}
                setExistingRows={setRows}
                rowToEdit={openEditSegmentDialog}
              />
              <Table stickyHeader>
                <TableHead sx={{ backgroundColor: "rgba(250, 250, 250, 1)" }}>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        sortDirection={orderBy === headCell.id ? order : false}
                        padding={"archived" === headCell.id ? "none" : "normal"}
                        sx={{
                          width: headCell.id === "name" ? "auto" : "1%",
                          whiteSpace: "nowrap",
                          "& path": {
                            fill: orderBy === headCell.id ? "black" : "none",
                            fillOpacity: "0.56",
                          },
                          "&:hover path": {
                            fill: orderBy === headCell.id ? "black" : "grey",
                            fillOpacity: "0.56",
                            transition: "fill 0.2s",
                          },
                          backgroundColor: "rgba(250, 250, 250, 1)",
                        }}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={() => handleRequestSort(headCell.id)}
                          IconComponent={() => (
                            <IconButton
                              sx={{
                                marginLeft: "4px",
                                padding: 0,
                                transition: "transform 0.2s",
                              }}
                              disableRipple
                            >
                              <OrderIcon order={order} />
                            </IconButton>
                          )}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsToDisplay.map((row) => (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={() => setOpenEditSegmentDialog(row)}
                      sx={{
                        cursor: "pointer",
                        "&:not(:hover) td": {
                          color: row.archived ? "#888" : "",
                        },
                      }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell padding="none" align="center">
                        {row.archived && (
                          <VisibilityOffOutlinedIcon
                            titleAccess="Hidden"
                            fontSize="small"
                            sx={{ verticalAlign: "middle", opacity: "0.8" }}
                          />
                        )}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={row.mmaType.split(" ")[0]}
                          size={"small"}
                          variant={row.mmaType}
                        />
                      </TableCell>
                      <TableCell>
                        {row.modifiedDate
                          ? formatDate(new Date(row.modifiedDate))
                          : ""}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {row.modifiedBy}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
      <Copyright />
    </Box>
  );
};
