import { useEffect, useState } from "react";
import $ from "jquery";
import apiURL from "../../config/environment";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import { isNumberPositiveInt } from "../../utils";
import CheckIcon from "@mui/icons-material/Check";

export const EditIntersectionDialog = ({
  handleClose,
  existingRows,
  setExistingRows,
  rowToEdit,
}) => {
  const [siteId, setSiteId] = useState(rowToEdit.id);
  const [location, setLocation] = useState(rowToEdit.shortName);
  const [detectors, setDetectors] = useState(rowToEdit.detectors);
  const [pedButtons, setPedButtons] = useState(rowToEdit.pedButtons * 2);
  const [archived, setArchived] = useState(rowToEdit.archived ? true : false);

  useEffect(() => {
    if (rowToEdit) {
      setSiteId(rowToEdit.id);
      setLocation(rowToEdit.shortName);
      setDetectors(rowToEdit.detectors);
      setPedButtons(rowToEdit.pedButtons * 2);
      setArchived(rowToEdit.archived);
    }
  }, [rowToEdit]);

  const [siteIdError, setSiteIdError] = useState(null);
  const [detectorsError, setDetectorsError] = useState(null);
  const [pedButtonsError, setPedButtonsError] = useState(null);
  const [saveError, setSaveError] = useState(false);

  const handleDialogClose = () => {
    setSiteIdError(null);
    setDetectorsError(null);
    setPedButtonsError(null);
    setSaveError(false);
    handleClose();
  };

  useEffect(() => {
    isNumberPositiveInt(siteId, "Site ID", setSiteIdError);
    isNumberPositiveInt(detectors, "Detectors", setDetectorsError);
    isNumberPositiveInt(pedButtons, "Pedestrian Buttons", setPedButtonsError);
  }, [siteId, detectors, pedButtons]);

  if (!rowToEdit) return null;

  const handleSave = () => {
    if (
      !isNumberPositiveInt(siteId, "Site ID", setSiteIdError) ||
      !isNumberPositiveInt(detectors, "Detectors", setDetectorsError) ||
      !isNumberPositiveInt(pedButtons, "Pedestrian Buttons", setPedButtonsError)
    ) {
      return;
    }

    if (!existingRows.find((row) => row.id === siteId)) {
      setSiteIdError("Site ID does not exist");
      return;
    }
    const token = JSON.parse(localStorage.getItem("userInfo"))?.token;
    $.ajax({
      method: "PATCH",
      url: apiURL + "admin/site/scats",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      contentType: "application/json",
      data: JSON.stringify({
        id: siteId,
        shortName: location,
        detectors: detectors,
        pedButtons: (pedButtons * 1) / 2,
        archived: archived,
      }),
    })
      .done((data) => {
        const filteredRows = existingRows.filter(
          (row) => row.id !== rowToEdit.id
        );
        setExistingRows([...filteredRows, data]);
        handleDialogClose();
      })
      .fail((jqXHR, textStatus, errorThrows) => {
        console.error(
          "Error adding intersection",
          jqXHR,
          textStatus,
          errorThrows
        );
        setSaveError(true);
      });
  };

  return (
    <Dialog open={true} onClose={() => handleDialogClose()}>
      <Box sx={{ width: "444px", padding: "16px 24px 24px 24px" }}>
        <DialogTitle sx={{ padding: 0 }} id="form-dialog-title">
          Edit Intersection
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            {saveError && (
              <Alert severity={"error"}>
                Unable to save. Please try again later.
              </Alert>
            )}
            <TextField
              autoFocus
              id="name"
              type="text"
              fullWidth
              label="Site ID"
              value={siteId}
              onChange={(e) => setSiteId(e.target.value)}
              error={siteIdError}
              helperText={siteIdError}
              disabled={true}
            />
            <TextField
              id="id"
              type="text"
              fullWidth
              label="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />

            <Box display={"flex"} gap={"16px"}>
              <TextField
                error={detectorsError}
                helperText={detectorsError}
                type="number"
                label="Detectors"
                value={detectors}
                onChange={(e) => setDetectors(e.target.value)}
                fullWidth
                disabled
              />
              <TextField
                error={pedButtonsError}
                helperText={pedButtonsError}
                type="number"
                label="Ped Buttons"
                value={pedButtons}
                onChange={(e) => setPedButtons(e.target.value)}
                fullWidth
                inputProps={{
                  min: 0,
                  step: 2,
                }}
                disabled
              />
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={archived}
                    onChange={(e) => setArchived(e.target.checked)}
                  />
                }
                label="Hidden"
                aria-describedby="hiddenHelp"
                style={{ paddingLeft: "13px" }}
              />
              <FormHelperText id="hiddenHelp">
                Hidden intersections are not included in metric calculations
              </FormHelperText>
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: 0, marginTop: "16px" }}>
          <Button onClick={() => handleDialogClose()} color="primary">
            Cancel
          </Button>
          <Button
            onClick={(e) => handleSave(e)}
            disabled={
              !siteId ||
              !location ||
              !detectors ||
              !pedButtons ||
              siteIdError ||
              detectorsError ||
              pedButtonsError
            }
            variant={"contained"}
            sx={{
              backgroundColor: "rgba(25, 118, 210, 1)",
              gap: "8px",
            }}
          >
            <CheckIcon sx={{ width: "16px", height: "24px" }} />
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
