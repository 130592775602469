import * as React from "react";

//React router dom
import { Route, Routes } from "react-router-dom";

//Context
import { TimeContext, timeConfigs } from "../../config/time";

import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";

//Selfmade components
import Navbar from "../../components/navigation/navbar";
import Dashboard from "./dashboard";
import EmbedViewer from "./embed";
import { IntersectionsTab } from "../../components/admin/intersectionsTab";
import { SegmentsTab } from "../../components/admin/segmentsTab";
import { BikeCountersTab } from "../../components/admin/bikeCountersTab";

export default function Home(props) {
  const [timeConfig, setTimeConfig] = React.useState(timeConfigs.currentYear);

  const { isAuthenticated } = props;

  const time = isAuthenticated ? localStorage.getItem("timeConfig") : "cyear";

  //Time effect
  React.useEffect(() => {
    switch (time) {
      case "cyear":
        setTimeConfig(timeConfigs.currentYear);
        break;

      case "lyear":
        setTimeConfig(timeConfigs.lastYear);
        break;

      default:
        setTimeConfig(timeConfigs.currentYear);
        break;
    }
  }, [time]);

  return (
    <TimeContext.Provider value={{ timeConfig, setTimeConfig }}>
      <CssBaseline />
      <Navbar isAuthenticated={isAuthenticated} />
      <Box component="main" sx={{ bgcolor: "#C1CCD2" }}>
        <Toolbar />
        <Routes>
          <Route
            path="/"
            element={
              <Dashboard isAuthenticated={isAuthenticated} page={"traffic"} />
            }
          />
          <Route
            path="/maintenance"
            element={
              <Dashboard
                isAuthenticated={isAuthenticated}
                page={"maintenance"}
              />
            }
          />
          <Route path="/Curbside" element={<EmbedViewer />} />
          {isAuthenticated && (
            <>
              <Route
                path="/administration/intersections"
                element={<IntersectionsTab />}
              />
              <Route
                path="/administration/segments"
                element={<SegmentsTab />}
              />
              <Route
                path={"/administration/bikeCounters"}
                element={<BikeCountersTab />}
              />
            </>
          )}
        </Routes>
      </Box>
    </TimeContext.Provider>
  );
}
